<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Ajouter un nouveau  réseaux sociaux") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
            md="3"
          >
            <div
              class="me-3 "
              style="width: 100px"
            >
              <span>{{ $t("Photo") }}</span>
              <file-upload
                v-model="item.logo"
                add-class="mx-auto justify-center"
                :label="$t('Upload new photo')"
                folder="images/networks/logos/"
                :btn="true"
                :extra="false"
                :hide-label="false"
                icon-name="mdi-pencil"
                :icon="false"
                :show-download="false"
                inputname="file"
                :crop="true"
                :aspect-ratio="1"
                :max-crop-height="300"
                :max-crop-width="300"
                accept="image/*"
              >
                <template #input>
                  <v-avatar
                    :color="item.logo ? '' : 'primary'"
                    :class="item.logo ? '' : 'v-avatar-light-bg primary--text'"
                    size="100"
                    rounded
                    class="mb-4"
                  >
                    <v-img
                      v-if="item.logo"
                      :lazy-src="require(`@/assets/images/avatars/1.png`)"
                      :src="item.logo || require(`@/assets/images/avatars/1.png`)"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-semibold text-5xl"
                    >{{ avatarText(item.label) }}</span>
                    <v-icon
                      size="20"
                      color="primary"
                      style="position: absolute;bottom: 10px; left: 10px"
                    >
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                  </v-avatar>
                </template>
              </file-upload>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              v-model="item.logo"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.logo"
              :label="$t('Logo')"
              :placeholder="$t('Logo')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="item.label"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.label"
              :label="$t('Nom')"
              :placeholder="$t('Nom')"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="item.color"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.color"
              :label="$t('Coleur')"
              :placeholder="$t('Coleur')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
        <slot name="form-cancel">
          </slot><v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import controller from './Controller'
import FileUpload from "@/components/file_upload.vue"


export default {
  components: {FileUpload},
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingNetworks = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('network/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      resetItem,
      form,
      isLoadingNetworks,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,
      avatarText,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
